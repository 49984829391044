$gutter: 1rem !default;

// Box grids
// ----------------------------------------------------------------

$grid-item-width       : 300px; // collapses to 1 per row on mobile
$grid-small-item-width : 150px; // collapses to 2 per row on mobile
$grid-med-item-width   : 215px; // collapses to 1 per row on mobile, up to 5 across on desktop
$grid-large-item-width : 340px; // 1 per row on mobile, 2 per row on desktop



// Flexible grid allows for varying number of columns
// min grid item width: 300px, collapses to 1 per row on mobile
.grid {
  --grid-item-width: #{$grid-item-width};
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: $gutter;

  > * {
    flex: 1 1 $grid-item-width; // fallback for IE11
    flex-basis: var(--grid-item-width);
    margin: $gutter / 2;
  }

  @supports(display: grid) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--grid-item-width), 1fr));

    > * {
      margin: 0;
      height: 100%; // ensure all sub-items fill grid space
    }
  }
}

.grid-small { --grid-item-width: #{$grid-small-item-width}; }
.grid-med   { --grid-item-width: #{$grid-med-item-width}; }
.grid-large { --grid-item-width: #{$grid-large-item-width}; }

// Grids with fixed number of items
.grid-2up,
.grid-3up,
.grid-4up,
.grid-5up,
.grid-6up {
  display: grid;
  gap: $gutter;
}

@include breakpoint($lg) {
  .grid-2up { grid-template-columns: repeat(2, 1fr); }
  .grid-3up { grid-template-columns: repeat(3, 1fr); }
  .grid-4up { grid-template-columns: repeat(4, 1fr); }
  .grid-5up { grid-template-columns: repeat(5, 1fr); }
  .grid-6up { grid-template-columns: repeat(6, 1fr); }
}