// Mixins
// ----------------------------------------------------------------

@function em($pixels, $context: $base-font-size) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1em;
}

@mixin leader($lines: 1, $context: $base-font-size) {
  margin-top: em($lines * $base-line-height, $context);
}

@mixin padding-leader($lines: 1, $context: $base-font-size) {
  padding-top: em($lines * $base-line-height, $context);
}

@mixin trailer($lines: 1, $context: $base-font-size) {
  margin-bottom: em($lines * $base-line-height, $context);
}

@mixin padding-trailer($lines: 1, $context: $base-font-size) {
  padding-bottom: em($lines * $base-line-height, $context);
}

@mixin rhythm($leader: 0, $padding-leader: 0, $padding-trailer: 0, $trailer: 0, $context: $base-font-size) {
  @include leader($leader, $context);
  @include padding-leader($padding-leader, $context);
  @include padding-trailer($padding-trailer, $context);
  @include trailer($trailer, $context);
}

@mixin clearfix {
  overflow: hidden;
}

@mixin pie-clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  *zoom: 1;
}

@mixin ellipsis ($max-width: 100%){
  display: inline-block;
  max-width: $max-width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin hide-text {
  text-indent: -119988px;
  overflow: hidden;
  text-align: left;
}