// Footer
// ----------------------------------------------------------------

.site-footer {
  font-size: 0.8rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  
  > .container {
    border-top: 2px solid palette(blue, light);
  }
  
  a {
    color: inherit;
  }
}