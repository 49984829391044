// General Styles
// ----------------------------------------------------------------

* {
  box-sizing: border-box;
}

html {
  background-color: $footer-bg-color;
  height: 100%;
}

body {
  background-color: $body-bg-color;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  > header { flex: 0 0 auto; }
  > main { flex: 1 0 auto; }
  > footer { flex-shrink: 0; }
}

main {
  padding-bottom: 2rem;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
  width: 100%;
  max-width: 58.5rem;
}

// Layouts
// ----------------------------------------------------------------

.content-med {
  margin-left: auto;
  margin-right: auto;
  max-width: 38rem;
}

.content-narrow {
  margin-left: auto;
  margin-right: auto;
  max-width: 28rem;
}


// Stripes
// ----------------------------------------------------------------

.stripe {
  padding-top: 2rem;
  padding-bottom: 2rem;
}