$sidebar-width: 28rem;
$not-sidebar-min-width: 60%;

.has-sidebar {
  display: flex;
  flex-wrap: wrap;
}

.sidebar {
  flex-basis: $sidebar-width;
  flex-grow: 1;
}

.not-sidebar {
  flex-basis: 0;
  flex-grow: 999;
  min-width: $not-sidebar-min-width;
}