$stack-space       : 1.5rem !default;
$stack-border-color: #dbe3e5 !default;
$stack-border-width: 1px !default;

// Vertically stacked elements with margin between each
.stack {
  // TODO: add with fallback for non-CSS vars supported browsers
  // --space: 1.5rem;
}

.stack > * {
  margin-top: 0;
  margin-bottom: 0;
} 

.stack > * + * {
  // margin-top: var(--space);
  margin-top: $stack-space;
}

// Recursive stacking applies margins between all descendants
.stack-recursive * + * {
  margin-top: $stack-space;
}

// Bordered stack has divider lines between
.stack-bordered > * + * {
  padding-top: $stack-space;
  border-top: $stack-border-width solid $stack-border-color;
}