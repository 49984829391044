// Images
// ----------------------------------------------------------------
$img-margin: 1em !default;
$img-border: #afbfd0 !default;

img {
  max-width: 100%;
  height: auto !important;
}

a img {
  display: block;
}

.img-right {
  float: right;
  margin: 0 0 $img-margin $img-margin;
}
.img-left {
  float: left;
  margin: 0 $img-margin $img-margin 0;
}

// Figure tags and alignment
$img-align-bp: 650px;

figure > img {
  display: block;
}

figcaption {
  @include leader(.5);
  text-align: center;
  font-style: italic;
  font-size: .9em;
  color: $quiet-font-color;
}