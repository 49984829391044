// Typography
// ----------------------------------------------------------------

// Base Type styles/sizes
// ----------------------------------------------------------------

:root {
  font-family: $base-font-family;
  line-height: $base-line-height;
  font-size: $base-font-size * .75;

  @include breakpoint($lg) {
    font-size: $base-font-size;
  }
}

// Links
// ----------------------------------------------------------------

@import "modules/links";
a {
  @include link-colors($link-color, darken($link-color, 10));
  transition: all .25s;
}

// Images
// ----------------------------------------------------------------
@import "modules/images";

figure {
  margin: 0;
}


// Headings
// ----------------------------------------------------------------

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  font-family: $heading-font;
  color: $heading-color;
}

h1 { font-size: $h1-font-size; line-height: 1.2; }
h2 { font-size: $h2-font-size; line-height: 1.2; }
h3 { font-size: $h3-font-size; }
h4 { font-size: $h4-font-size; color: #222; }
h5 { font-size: $h5-font-size; }
h6 { font-size: $h6-font-size; }

.h1 { @extend h1; }
.h2 { @extend h2; }
.h3 { @extend h3; }
.h4 { @extend h4; }
.h5 { @extend h5; }
.h6 { @extend h6; }

.page-title {
  font-size: 3rem;
}

// Body Text
// ----------------------------------------------------------------

body {
  color: $base-font-color;
}


hr {
  border: none;
  background: $divider-color;
  height: 1px;
  width: 100%;
  margin-top: $base-line-height - 2;
  margin-bottom: $base-line-height - 2;
  position: relative;
  top: -1px;
  clear: both;
}


// Tables
// ----------------------------------------------------------------

$table-disabled-color: lighten(palette(grey, light), 5%);

table {
  width: 100%;
  border-collapse: collapse;

  &.hover {
    @include breakpoint($lg) {
      tbody tr:hover {
        td, th {
          background-color: #fffceb;
        }
      }
    }
  }

  &.simple {
    th, td {
      border: none;
    }
  }

  &.compact {
    th, td {
      padding: 0 .5em;
    }
  }

  th,td {
    padding: .5em;
    border-bottom: 1px solid #eeeeee;
    vertical-align: top;
    text-align: left;
  }

  thead {
    th {
      font-size: 14px;
      letter-spacing: 1px;
      font-weight: normal;
      text-transform: uppercase;
      color: #888888;
      border-bottom: 2px solid #dddddd;
      vertical-align: bottom;
      white-space: nowrap;
    }
  }

  tbody {
    th { font-weight: bold; }
  }

  tr.odd td {
    background: #fffce5;
  }

  .disabled {
    background-color: $table-disabled-color;
    color: $quiet-font-color;
    border-bottom-color: palette(grey, x-light);

    a {
      color: inherit;
    }
  }
  
  caption {
    text-align: center;
  }

  .actions {
    text-align: right;
  }
}

// Responsive Tables
// ref: http://css-tricks.com/responsive-data-tables/
// ----------------------------------------------------------------
$resp-table-label-width: 30%;

@include breakpoint(max-width $lg) {
  table.responsive {
    display: block;
    font-size: .8em;

    // Force table to not be like tables anymore
    thead, tbody, th, td, tr { 
      display: block; 
    }

    // Hide table headers (but not display: none;, for accessibility)
    thead tr { 
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border-bottom: 3px solid #eee;
      padding-bottom: 1em;
      padding-top: 1em;
    }
    
    td { 
      // Behave  like a "row"
      border: none;
      border-bottom: 1px solid #eee; 
      position: relative;

      &[data-label] {
        padding-left: $resp-table-label-width + 3%;
      }

      &:last-child,
      &.no-border {
        border-bottom: none;
      }

      &.actions {
        padding-left: 0;
        text-align: center;
        &:before {
          content: '' !important;
        }
      }
    }
    
    td:before { 
      content: attr(data-label); // Add a label from the data-label attribute
      position: absolute; // Now like a table header
      top: 6px; // Top/left values mimic padding
      left: 6px;
      width: $resp-table-label-width; 
      white-space: nowrap;
      text-align: left;
      font-size: .9em;
      color: $quiet-font-color;
    }
  }
}


// Lists
// ----------------------------------------------------------------
@import "modules/lists";
li ul,
li ol {
  margin-top: 0;
  margin-bottom: .5em;
}
li ol {
  list-style: lower-alpha;
}
ul.unstyled,
li.unstyled {
  @include unstyled-list;
}


// Horizontal Rules
// ----------------------------------------------------------------
hr {
  border: none;
  background-color: $divider-color;
  height: 3px;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: relative;
  top: -1px;
  clear: both;

  &.chevrons {
    background: transparent url(/images/chevrons-sm.svg) 0 0 repeat-x;
    height: 97px;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}


// Misc
// ----------------------------------------------------------------
address {
  font-style: normal;
}
abbr {
  border: none;
}
.hidden {
  display: none;
}


.text-quiet   { color: $quiet-font-color; }
.text-quiet a { color: inherit; }

.text-center { text-align: center; }
.text-right  { text-align: right; }
.text-left   { text-align: left; }

.text-thin   { font-weight: 300; }

.text-invert {
  color: #fff;

  a { color: inherit; }
}

.text-small   { font-size: .9em; }
.text-x-small { font-size: .7em; }

.truncate {
  display: inline-block;
  width: 18em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-margin           { margin: 0 !important; }
.no-trailer          { margin-bottom: 0 !important; }
.no-padding-trailer  { padding-bottom: 0 !important; }
.no-leader           { margin-top: 0 !important; }
.no-padding-leader   { padding-top: 0 !important; }

.has-leader          { margin-top: 1rem; }
.has-trailer         { margin-bottom: 1rem; }
.has-leader-wide     { margin-bottom: 2rem; }
.has-trailer-wide    { margin-bottom: 2rem; }

.has-padding-leader       { padding-top: 1rem; }
.has-padding-trailer      { padding-bottom: 1rem; }
.has-padding-leader-wide  { padding-top: 2rem; }
.has-padding-trailer-wide { padding-bottom: 2rem; }
