@mixin link-colors($link,$hover) {
  color: $link;
  &:hover {
    color: $hover;
  }
}

@mixin unstyled-link-with-hover() {
  @include unstyled-link;
  &:hover, &:focus {
    text-decoration: underline;
    color: $link-color;
    cursor: pointer;
  }
}

@mixin unstyled-link {
  color: inherit;
  text-decoration: inherit;
  border-bottom: none !important;
  &:active, &:focus {
    outline: none;
  }
}

.unstyled-link { @include unstyled-link; }
.unstyled-link-with-hover { @include unstyled-link-with-hover; }

a.learn-more {
  transition: all .25s;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%20standalone%3D%22no%22%3F%3E%3Csvg%20width%3D%229px%22%20height%3D%2215px%22%20viewBox%3D%220%200%209%2015%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20xmlns%3Asketch%3D%22http%3A%2F%2Fwww.bohemiancoding.com%2Fsketch%2Fns%22%3E%20%20%20%20%20%20%20%20%3Ctitle%3Earrow-right%3C%2Ftitle%3E%20%20%20%20%3Cdesc%3ECreated%20with%20Sketch.%3C%2Fdesc%3E%20%20%20%20%3Cdefs%3E%3C%2Fdefs%3E%20%20%20%20%3Cg%20id%3D%22Page-1%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20sketch%3Atype%3D%22MSPage%22%3E%20%20%20%20%20%20%20%20%3Cg%20id%3D%22features%22%20sketch%3Atype%3D%22MSArtboardGroup%22%20transform%3D%22translate(-409.000000%2C%20-1563.000000)%22%20stroke%3D%22%23DABB4B%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22square%22%3E%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M410.5%2C1576.34924%20L416.349242%2C1570.5%20L410.5%2C1564.65076%22%20id%3D%22arrow-right%22%20sketch%3Atype%3D%22MSShapeGroup%22%3E%3C%2Fpath%3E%20%20%20%20%20%20%20%20%3C%2Fg%3E%20%20%20%20%3C%2Fg%3E%3C%2Fsvg%3E');
  background-position: 0 50%;
  background-repeat: no-repeat;
  padding-left: 18px;
  border: none !important;

  &:hover {
    margin-left: 9px;
  }
}

.block-link {
  text-decoration: none;
  color: inherit;

  &:hover {
    color: inherit;
  }
}

// Anchor links generated by Craft anchor plugin (https://github.com/pixelandtonic/Anchors)
// hide these unless hovered over
h1, h2, h3, h4, h5, h6 {
  a.anchor {
    opacity: 0;
    display: none;
    border: none !important;
    color: #ccc !important;
  }
  &:hover a.anchor {
    display: inline;
    opacity: 100;
  }
}