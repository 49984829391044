// Colors
// ----------------------------------------------------------------
$palettes: (
  grey: (
    x-light: #e5e5e5,
    light: #cdcdcd,
    mid-light: #a5a9ac,
    base: #808080,
    mid-dark: #555,
    dark: #303030,
    x-dark: #222222
  ),
  blue: (
    light: #82dfff,
    base: #00a8e1
  )
);

@function palette($palette, $tone: 'base') {
  @return map-get(map-get($palettes, $palette), $tone);
}

$link-color         : palette(blue);

$header-bg-color    : palette(blue);
$body-bg-color      : #fff;
$footer-bg-color    : #fff;

$divider-color      : #dde4eb;

$base-font-color    : palette(grey, x-dark);
$quiet-font-color   : palette(grey, light);
$heading-color      : palette(blue);

$hover-color        : palette(blue, light);

$alert-color        : orange;
$error-color        : red;




// Fonts
// ----------------------------------------------------------------

// External Fonts
@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap');

$sans-font                 : "Noto Sans", sans-serif;
$serif-font                : Times, serif;
$fixed-font                : monospace, sans-serif;
$mono-font                 : $fixed-font;
$heading-font              : $sans-font;

$base-font-size            : 20px;
$base-line-height          : 1.5;

$base-font-family          : $sans-font;

$h1-font-size              : 2.25em;
$h2-font-size              : 1.5em;
$h3-font-size              : 1.25em;
$h4-font-size              : 1em;
$h5-font-size              : 1em;
$h6-font-size              : 0.83em;


// CSS3 Mixin Defaults
// ----------------------------------------------------------------
$default-border-radius: 3px;

// Text Shadow
$default-text-shadow-color   : rgba(0,0,0,.25);
$default-text-shadow-h-offset: 0;
$default-text-shadow-v-offset: 1px;
$default-text-shadow-blur    : 1px;

// Box Shadow
$default-box-shadow-color   : rgba(0,0,0,.5);
$default-box-shadow-h-offset: 0;
$default-box-shadow-v-offset: 2px;
$default-box-shadow-blur    : 16px;
$default-box-shadow-spread  : 0;
$default-box-shadow         : $default-box-shadow-color $default-box-shadow-h-offset $default-box-shadow-v-offset $default-box-shadow-blur $default-box-shadow-spread;


// Transitions
$default-transition-duration: .25s;

// Buttons
$default-button-bg:         palette(blue);
$default-button-text-color: white;


// Grid
// ----------------------------------------------------------------

$gutter: 2rem;



// Breakpoints
// ----------------------------------------------------------------

$med    : 480px;
$med-lg : 740px;
$lg     : 900px;
$xl     : 1170px;


// Misc
// ----------------------------------------------------------------