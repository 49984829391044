// Switcher

$switcher-gutter     : $gutter !default;
$switcher-child-width: 16rem;
$switcher-column-max : 5;

.switcher > * {
  display: flex;
  flex-wrap: wrap;
  margin: ($switcher-gutter / 2) * -1;
}

.switcher > * > * {
  flex-grow: 1;
  // flex-basis: calc(#{$switcher-child-width} - calc(100% - 2rem) * 999);
  flex-basis: calc((#{$switcher-child-width} - (100% - #{$switcher-child-width})) * 999);
  
  margin: $switcher-gutter / 2;
}

.switcher > * > :nth-last-child(n+$switcher-column-max),
.switcher > * > :nth-last-child(n+$switcher-column-max) ~ * {
  flex-basis: 100%;
}