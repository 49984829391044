@charset "UTF-8";

// Includes
@import "../../node_modules/normalize.css/normalize.css";
@import "../../node_modules/breakpoint-sass/stylesheets/breakpoint";

// Utilities
@import "utils/mixins";
@import "utils/shapes";

// Base vars and setup
@import "base/variables";
@import "base/typography";

// Layout
@import "layout/grid";
@import "layout/media";
@import "layout/sidebar";
@import "layout/stack";
@import "layout/switcher";

// Partials
@import "partials/general";
@import "partials/header";
@import "partials/footer";

// Pages
@import "pages/home";