@mixin pipe-nav-list($padding: 12px, $direction: left) {
  @include horizontal-list-container;
  li {
    @include horizontal-list-item($padding, $direction);
    border-right: 1px solid;
    border-color: rgba(255,255,255,.3);
    padding: 0 $padding;
    &:last-child, &.last {
      border-right: none;
    }
    a {
      display: inline-block;
    }
  }
}

@mixin comma-separated-list() {
  @include delimited-list(', ');
  li { background: none; }
}

@mixin unstyled-list() {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  li {
    margin-left: 0;
    padding-left: 0;
    background: none;
  }
}

@mixin horizontal-list($padding: 0) {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    @if $padding {
      padding: 0 $padding;
    }

    &:first-child {
      padding-left: 0;
    }
    
    &:last-child {
      padding-right: 0;
    }
  }
}

.link-list {
  a {
    display: block;
    text-decoration: none;
    padding: .5em;
    border-radius: $default-border-radius;
    transition: all .25s;

    &:hover {
      background-color: $hover-color;
    }
  }
}

.checklist {
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    padding-left: 30px;
    margin-bottom: .3em;
    background: transparent url(/images/svg-icons-source/check.svg) 0 0 no-repeat;
  }
}

.chevron-list {
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    padding-left: 2rem;
    margin-bottom: 1em;
    background: transparent url(/images/chevron-red.svg) 0 0.25em no-repeat;
  }
}