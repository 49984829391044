// Home
// ----------------------------------------------------------------

.home-banner {
  margin: 1rem;
  padding: 2rem 2rem 10rem;
  background: $header-bg-color url(/images/boxes.jpg) 50% 50% / cover no-repeat;
  color: #fff;

  .pre-heading {
    color: #fff;
  }

  img {
    display: inline-block;
  }
}

.intro {
  margin-top: -8rem;
}

.intro .box {
  background-color: #fff;
  box-shadow: rgba(0,0,0,.20) 0 9px 10px;
}
.intro-content {
  padding: 2rem;
  position: relative;
  z-index: 100;
  background-image: linear-gradient(to right, rgba(255,255,255,.6), rgba(255,255,255,.5), rgba(255,255,255,0));
}

.movie-item .media-item {
  text-align: center;
  padding: .5em;
}
.movie-item img {
  box-shadow: rgba(0,0,0,.3) 0 2px 4px;
  margin-bottom: .75em;
}
.movie-item h4 {
  margin: 0 0 .5em;
}


// Truck
.truck-bg {
  background: transparent url(/images/truck-bg.svg) 0 100% repeat-x;
  width: 100%;
  height: 100%;
  position: relative;
  animation: animateBG 10s linear infinite;
}
.truck-bg::before,
.truck-bg::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}
.truck-bg::before {
  background: transparent url(/images/truck-mg.svg) 0 100% repeat-x;
  animation: animateMG 5s linear infinite;
  z-index: 0;
}
.truck-bg::after {
  background: transparent url(/images/truck-road.svg) 0 100% repeat-x;
  animation: animateRoad .5s linear infinite;
  z-index: 20;
}

.truck {
  position: relative;
  z-index: 30;
  height: 100%;
  min-height: 200px;
  
  svg {
    position: absolute;
    bottom: 12%;
    max-width: 100%;
  }
}
#truck-body {
  animation: animateTruck .25s linear infinite;
  transform-origin: 60% 50%;
}
#truck-wheel1 {
  animation: animateWheel1 1s linear infinite;
  transform-origin: 5.8% 13.5%;
}
#truck-wheel2 {
  animation: animateWheel2 1s linear infinite;
  transform-origin: 60.8% 13.5%;
}

@keyframes animateBG {
	from { background-position: 0 100%; }
	to { background-position: -440px 100%; }
}
@keyframes animateMG {
	from { background-position: 0 100%; }
	to { background-position: -510px 100%; }
}
@keyframes animateRoad {
	from { background-position: 0 100%; }
	to { background-position: -152px 100%; }
}
@keyframes animateTruck {
  0%  { transform: rotate(0deg) translateX(22px); }
	25% { transform: rotate(.35deg) translateX(22px); }
  50%  { transform: rotate(0deg) translateX(22px); }
	75% { transform: rotate(-.35deg) translateX(22px); }
  100%  { transform: rotate(0deg) translateX(22px); }
}
@keyframes animateWheel1 {
  from { transform: rotate(0deg); }
	to { transform: rotate(360deg); }
}
@keyframes animateWheel2 {
  from { transform: rotate(0deg) translateX(290px); }
	to { transform: rotate(360deg) translateX(290px);; }
}